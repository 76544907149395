import React from "react";

import { Link } from "gatsby";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

import "./about.css";

const DigitalImages = () => {
  return (
    <Layout>
      <Head title="Digital Images" />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1562812948/digital/13.jpg"
        alt="digital glitch art, red and teal"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1562812948/digital/14.jpg"
        alt="black and white glitch art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1562812948/digital/5.jpg"
        alt="abstract glitch art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1562812948/digital/10.jpg"
        alt="rainbow multicolored glitch art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1562812948/digital/9.jpg"
        alt="bay area glitch art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1562812948/digital/3.jpg"
        alt="cool arctic digital glitch art"
      />
      <Link to="/media">
        <h3>Media</h3>
      </Link>
      <Link to="/">
        <h3>Home</h3>
      </Link>
    </Layout>
  );
};

export default DigitalImages;
